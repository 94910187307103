// Sound

export function getSoundHashFromCatalogPage(page)
{
  let parser = new DOMParser();
  let htmlDoc = parser.parseFromString(page, 'text/html');
  let elems = htmlDoc.getElementsByClassName("icon-play");
  if(elems.length !== 0)
  {
    return elems[0].getAttribute("data-mediathumb-url");
  }
  else
  {
    return page;
  }
}

export function getSoundHashFromAssetId(assetId)
{
  let query=`https://api.round-cloud.com:58750/https://www.roblox.com/library/${assetId}`;

  return fetch(query)
    .then(res => res.text())
    .then(text => getSoundHashFromCatalogPage(text));
}

export function getSoundInfoFromCatalogPage(page)
{
  let parser = new DOMParser();
  let htmlDoc = parser.parseFromString(page, 'text/html');
  let elems = htmlDoc.getElementsByClassName("icon-play");

  let audioURL = "";
  if(elems.length !== 0)
  {
    audioURL = elems[0].getAttribute("data-mediathumb-url");
  };

  let imageURL = "";
  
  let elem = htmlDoc.getElementById("AssetThumbnail");
  if(elem)
  {
    imageURL = elem.textContent;

    elems = elem.getElementsByClassName("thumbnail-span");
    imageURL = elems.textContent;

    if(elems.length !==  0)
    {
      let images = elems[0].getElementsByTagName("img");
      imageURL = images.tetextContentxt;
      if(images.length !== 0)
      {
        imageURL = images[0].getAttribute("src");
      }
    }
  }
  return {audioURL, imageURL};
}

export function getSoundInfoFromAssetId(assetId)
{
  let query=`https://api.round-cloud.com:58750/https://www.roblox.com/library/${assetId}`;

  return fetch(query)
    .then(res => res.text())
    .then(text => getSoundInfoFromCatalogPage(text));
}
