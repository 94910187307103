import React from 'react';
import Search from "./Search.js"

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    return (
      <Search creatorID="1" />
    );
  }
}

export default Home;