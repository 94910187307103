import React from "react";

import SongThumb from "./SongThumb.js";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      items: [],
      keyword: props.keyword,
      creatorID: props.creatorID,
    };
  }

  componentDidMount() {
    let query = `https://api.round-cloud.com:58750/https://search.roblox.com/catalog/json?CatalogContext=2&Category=9`;
    if (this.state.keyword != null) {
      query += `&Keyword=${this.state.keyword}`;
    }
    if (this.state.creatorID != null) {
      query += `&CreatorID=${this.state.creatorID}`;
    }

    fetch(query)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      const list = items.map((x) => (
        <div key={x.Hash}>
          <SongThumb
            assetId={x.AssetId}
            name={x.Name}
            artist={x.Creator}
            linerNotes={x.Description}
          />
        </div>
      ));

      return (
        <div className="ro-searchpage">
          <h1>{this.state.keyword}</h1>
          <div className="ro-search">{list}</div>
        </div>
      );
    }
  }
}

export default Search;
