import React from 'react';

class Forbidden extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {

    return (
      <div>You must log in to access this page.</div>
    );
  }
}

export default Forbidden;