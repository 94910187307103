import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "react-fetch-hook";

function callAPI() {}

function Profile() {
  const { user, isAuthenticated, isLoadingAuth, getAccessTokenSilently } =
    useAuth0();
  const { isLoading, error, data } = useFetch("/api/public");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }).then((accessToken) => {
      console.log(JSON.stringify(accessToken));
      fetch("/api/private", {
        headers: { Authorization: `Bearer ${accessToken}` },
        scope: "read:stuff",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => setMessage(data.message));
    });
  });

  if (isLoadingAuth || isLoading) {
    return <div>Loading ...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }

  const message1 = data ? data.message : "";

  return (
    <div>
      <h2>
        {message1}:{message}
      </h2>
      <div>Nickame: {user.nickname}</div>
      <div>Name: {user.name}</div>

      <div>Everything: {JSON.stringify(user)}</div>
    </div>
  );
}

export default Profile;
