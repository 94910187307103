import React from 'react';
import {
  Link
} from "react-router-dom";
import 
{
  getSoundInfoFromAssetId 
} from './APIs.js'

import MediaElement from './MediaElement';

// TODO : Replace with just assetID and fetch
class Song extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      assetId: props.assetId, 
      name: props.name, 
      artist: props.artist, 
      linerNotes: props.linerNotes, 
      lyrics: "",
      audioURL: null,
      imageURL: null
    };
  }

  componentDidMount() {
    const assetId = this.state.assetId;
    if(assetId != null)
    {
      getSoundInfoFromAssetId(assetId)
      .then(
        (x) => {
          this.setState({
            audioURL: x.audioURL, 
            imageURL: x.imageURL, 
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
    }
  }

  render() {
    const assetId = this.state.assetId;
    const artist = this.state.artist;
    const name = this.state.name;
    const linerNotes = this.state.linerNotes;
    const lyrics = this.state.lyrics;
    const audioURL = this.state.audioURL;
    const imageURL = this.state.imageURL;

    const
    sources = [
      {src: audioURL, type: 'audio/mp3'},
      {src: audioURL, type: 'audio/ogg'}
    ],
    config = {},
    tracks = {}
  ;

  return (

    <div className="ro-songthumb" key={audioURL}>
      <Link to={`/song?assetId=${assetId}`
      +`&name=${name}`
      +`&artist=${artist}`
      +`&linerNotes=${linerNotes}`
      +`&lyrics=${lyrics}`}>
              <img src={imageURL} alt={name} />
        <div className="ro-name">{name}</div>
      </Link>
      {
      audioURL!=null && 
        <div className="ro-audio">
          <MediaElement 
            id={audioURL}
            mediaType="audio"
            preload="none"
            controls
            poster=""
            sources={JSON.stringify(sources)}
            options={JSON.stringify(config)}
            tracks={JSON.stringify(tracks)}
            />     
        </div>
      }
        <div>by <span className="ro-artist">{artist}</span></div>
    </div>
  );

/*
    return (

      <div className="ro-songthumb" key={audioURL}>
        <img src={imageURL}/>
        <Link to={`/song?assetId=${assetId}`
        +`&name=${name}`
        +`&artist=${artist}`
        +`&linerNotes=${linerNotes}`
        +`&lyrics=${lyrics}`}>
          <div className="ro-name">{name}</div>
        </Link>
        {
        audioURL!=null && 
          <div className="ro-audio">
            <MediaElement 
              id={audioURL}
              mediaType="audio"
              preload="none"
              controls
              poster=""
              sources={JSON.stringify(sources)}
              options={JSON.stringify(config)}
              tracks={JSON.stringify(tracks)}
              />     
          </div>
        }
        <div className="ro-artist">{artist}</div>
      </div>
    );
    */
  }
}

export default Song;
