import React from 'react';

const logo = process.env.PUBLIC_URL +"/logo.svg";

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  render() {
    return (
      <div className="ro-start">
        <h1>Round-Cloud</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Sound for everyone
        </p>
      </div>
    );
  }
}

export default Start; 