import React from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";

import Forbidden from "./pages/Forbidden.js";
import Home from "./pages/Home.js";
import LoginButton from "./pages/LoginButton.js";
import LogoutButton from "./pages/LogoutButton.js";
import PrivateRoute from "./pages/PrivateRoute.js";
import Profile from "./pages/Profile.js";
import Search from "./pages/Search.js";
import SongPage from "./pages/SongPage.js";
import Start from "./pages/Start.js";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const logo = process.env.PUBLIC_URL + "/logo.svg";

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const query = useQuery();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <nav>
        <ul>
          <li></li>
          <li></li>
          <li>
            <NavLink to="/start">
              <img src={logo} height="20px" alt="Round-Cloud logo" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/home">Home</NavLink>
          </li>
          <li>
            <NavLink to="/search?keyword=Glass">Search</NavLink>
          </li>
          <li className="ro-right">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </li>
          {isAuthenticated && (
            <li className="ro-right">
              <NavLink to="/profile">{user.name}</NavLink>
            </li>
          )}
        </ul>
      </nav>

      <section>
        {/*
        <div id="status">
          Filler
        </div>
        */}
      </section>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/forbidden">
          <Forbidden />
        </Route>
        <Route path="/start">
          <Start />
        </Route>
        <PrivateRoute path="/home">
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/search">
          <Search keyword={query.get("keyword")} />
        </PrivateRoute>
        <Route path="/song">
          <SongPage
            assetId={query.get("assetId")}
            name={query.get("name")}
            artist={query.get("artist")}
            linerNotes={query.get("linerNotes")}
            lyrics={query.get("lyrics")}
          />
        </Route>
        <PrivateRoute path="/profile">
          <Profile />
        </PrivateRoute>
        <Route path="/">
          <Redirect to="/start" />
        </Route>
      </Switch>

      <footer></footer>
    </div>
  );
}

function Shell() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      responseType="token id_token"
      scope="openid profile email read:stuff"
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  );
}

export default Shell;
