import React from 'react';
import 
{
  getSoundInfoFromAssetId 
} from './APIs.js'

import MediaElement from './MediaElement';

class SongPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      assetId: props.assetId, 
      name: props.name, 
      artist: props.artist, 
      linerNotes: props.linerNotes, 
      lyrics: null,
      audioURL: null,
      imageURL: null,
    };
  }


  componentDidMount() {
    const assetId = this.state.assetId;
    if(assetId != null)
    {
    getSoundInfoFromAssetId(assetId)
      .then(
        (x) => {
          this.setState({
            audioURL: x.audioURL, 
            imageURL: x.imageURL
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
    }
  }

  render() {
    const assetId = this.state.assetId;
    const artist = this.state.artist;
    const name = this.state.name;
    const linerNotes = this.state.linerNotes;
    const lyrics = this.state.lyrics;
    const audioURL = this.state.audioURL;
    const imageURL = this.state.imageURL;

		const
			sources = [
				{src: audioURL, type: 'audio/mp3'}
			],
			config = {},
			tracks = {}
		;

    return (
      <div className="ro-songpage">
        {
        imageURL!==null && <img src={imageURL} alt={name}/>          
        }
        <div className="ro-name">{name}</div>
        <div style={{"font-size":"8px"}}>id:{assetId}</div>
        by <span className="ro-artist">{artist}</span>
        <hr/>
        {
        audioURL!==null && 
          <div className="ro-audio">
            <MediaElement 
              id={audioURL}
              mediaType="audio"
              preload="none"
              controls
              poster=""
              sources={JSON.stringify(sources)}
              options={JSON.stringify(config)}
              tracks={JSON.stringify(tracks)}
              />     
          </div>
        }
        <h1>Notes</h1>
        <div className="ro-linerNotes">{linerNotes}</div>
        {lyrics!=null && <><hr/> <h1>Lyrics</h1> <div className="ro-lyrics">{lyrics}</div></>}
      </div>
      );

  }
}

export default SongPage;